import {parseUrlParams} from '../../../core/library/url';
import MediaSources from "../../media_sources";


export const abzac = {
    SHOW_LANGS: true,
    BASE_DOMAIN: 'https://absatz.media',
    APP_CLASS: 'vm',
    PROJECT_NAME: 'vm',
    PROJECT_PREFIX: 'vm',
    LOCALE: "ru",
    PREVIEW_DOMAIN: "https://absatz.media",
    PREVIEW_HASH: "AE30845FBF6EE09305710B753A9C264C",
    SOCKET: 'http://localhost:9001',

    COMMENTS: {
        HOST: '/comments_api/api/v1',
        SERVICES: ['youtube', 'telegram', 'vk'],
    },

    OAUTH: {
        // url for oauth domain
        URL: `https://oauth.absatz.media/`,
        // current project domain
        PROJECT_DOMAIN: `backend.absatz.media`,
        // current project url path
        PROJECT_PATH: `https://backend.absatz.media`,
        // path for oauth api
        API: `https://oauth.absatz.media/auth`,
        // path for oauth users
        OAUTH_USER_PATH: `https://oauth.absatz.media/users/`,
        // prefix for authorization tokens
        PREFIX: 'oauth_',
    },

    ELASTIC: {
        API: "https://backend.absatz.media/elastic"
    },
    PROJECT: {
        // project name
        NAME: 'vm',
        // project default layout
        LAYOUT: "default",
        // project api path
        API: `https://backend.absatz.media/api/backend`,
        ELASTIC_API: `https://backend.absatz.media/elastic`,
    },

    LANGS: [
        {title: "English", domain: "http://localhost:3000"},
        {title: "Russian", domain: "http://ru.localhost:3000"}
    ],

    UPLOAD: {
        "path": "https://backend.absatz.media/api/backend/media"
    },

    // vmru config
    VIEWS: {
        documents: {
            "article": "default_view_vm",
            "author": "authors_view",
            "category": "category_view",
            "tag": "tag_view",
            "trend": "trend_view",
            "page": "page_view",
            "show": "show_view",
            "newspaper": "newspaper_view",
            "rnewspaper": "rnewspaper_view",
            "schedule": "schedule_view",
            "arates": 'arates_view',
            "aviews": 'aviews_view',
            "mcat": 'anons_view',
            "polls": 'poll',
            "covid": "covid",
            "stop": "stopwords",
            "contacts": "contacts_index",
            "contacts_type": "contacts_types",
            "redirects": "RedirectsViews",
            "podcast": "PodcastView",
        },
        documents_add: {
            "article": "add_article_vm",
            "trend": "add_trend",
            "news": "add_news",
            "author": "add_author",
            "category": "add_category_rbth",
            "tag": "add_tag",
            "mcat": "anons_add",
            "banner": "add_banner",
            "page": "add_page",
            "newspaper": "add_newspaper",
            "rnewspaper": "add_rnewspaper",
            "episode": "add_episode",
            "battle": "add_battle",
            "schedule": "add_schedule",
            "arates": "arate_add",
            "aviews": "aviews_add",
            "infographic": "infographics_add",
            "polls": "poll_add",
            "covid": "covid",
            "afisha": "afisha_vm",
            "regionmat": "region_material",
            "mgallery": "mgallery",
            "stories": "stories",
            "receipt": "receipt",
            "stt": "stt",
            "stop": "add_stopwords",
            "contacts": "add_contacts",
            "redirects": "RedirectsAdd",
            "podcast": "AddPodcast",
            "pepisode": "AddPodcastEpisode",
            "quiz": "AddQuiz",
            "quizg": "AddQuizG",
        }
    },
    PHOTO_MENU: [{
        'path': '/',
        'component': 'media_gallery',
        'title': 'MEDIA_GALLERY_LIST',
        'icon': 'picture',
    },
    ],
    MENU: [
        {
            'title': 'DASHBOARD',
            'path': '/',
            'component': 'dashboard_index',
            'icon': 'dashboard',

        },

        {
            'title': 'DOCUMENTS',
            'path': '/documents/:type',
            'component': 'default_document',
            'subroutes': [
                {
                    'path': '/documents/:type/:id',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/:type/:id/preview',
                    'component': 'preview',
                },
                {
                    'path': '/documents/:type/create',
                    'component': 'add_documents',
                },
                {
                    'path': '/documents/episode/:id/chat',
                    'component': 'episode_chat',
                },
                {
                    'path': '/archive',
                    'component': 'archive',
                },
            ],
            'icon': 'container',
            'submenu': [
                {
                    'title': 'NEWS',
                    'path': '/documents/news',
                    'icon': 'bars',
                },
                {
                    'title': 'Articles',
                    'path': '/documents/article',
                    'icon': 'bars',
                },
                {
                    'title': 'MEANING',
                    'path': '/documents/meaning',
                    'icon': 'audit',
                },
                {
                    'title': 'Episode',
                    'path': '/documents/episode',
                    'icon': 'video-camera',
                },
                {
                    'title': 'MATERIAL_GALLERY',
                    'path': '/documents/mgallery',
                    'icon': 'appstore',
                },
                {
                    'title': 'Infographic',
                    'path': '/documents/infographic',
                    'icon': 'picture',
                },
                {
                    'title': 'STORIES',
                    'path': '/documents/stories',
                    'icon': 'instagram',
                },
                {
                    'title': 'POLLS',
                    'path': '/documents/polls',
                    'icon': 'experiment',
                },
                {
                    'title': 'PODCAST',
                    'path': '/documents/podcast',
                    'icon': 'database',
                },
                {
                    'title': 'PODCAST_EPISODE',
                    'path': '/documents/pepisode',
                    'icon': 'play-square',
                },
                {
                    'title': 'QUIZ',
                    'path': '/documents/quiz',
                    'icon': 'database',
                },
                {
                    'title': 'QUIZG',
                    'path': '/documents/quizg',
                    'icon': 'database',
                },
            ]
        },
        {
            title: 'DND',
            path: '/dnd',
            icon: 'appstore',
            component: 'DNDIndex',
        },
        {
            title: 'MEDIA_GALLERY_LIST',
            path: '/mediagallery',
            icon: 'picture',
            component: 'media_gallery',
        },
        {
            title: "INSTRUMENTS",
            icon: "code",
            'submenu': [
                {
                    'title': 'PUSH',
                    'path': '/push',
                    'icon': 'notification',
                    'component': 'push',
                },
                {
                    title: "COLLAGE_MAKER",
                    path: "/collage_maker",
                    icon: "picture",
                    component: 'CollageMaker',
                },
                {
                    'title': 'REDIRECTS',
                    'path': '/documents/redirects',
                    'icon': 'experiment',
                },
                {
                    'title': 'RSS',
                    'icon': 'database',
                    'path': '/rss_agg',
                    component: 'RSSIndexController',
                },
                {
                    'title': 'CONTACTS',
                    'icon': 'user',
                    'path': '/documents/contacts',
                },
                {
                    'title': 'TIMECALC',
                    'icon': 'clock-circle',
                    'path': '/timecaculator',
                    component: 'Timeclaculator',
                },
            ]
        },
        {
            title: 'COMMENTS',
            icon: 'message',
            submenu: [
                {
                    title: 'COMMENTS_SERVICES',
                    icon: 'setting',
                    path: '/comments/services',
                    component: 'CommentsServiceController',
                },
                {
                    title: 'COMMENTS_USERS',
                    icon: 'user',
                    path: '/comments/user',
                    component: "CommentsUsers"
                },
                {
                    title: 'COMMENTS_LIST',
                    icon: 'message',
                    path: '/comments',
                    component: 'CommentsController',
                },
                {
                    title: 'COMMENTS_STOP_WORDS',
                    icon: 'number',
                    path: '/comments/stopwords',
                    component: 'StopWordsComments',
                }
            ]
        },
        {
            title: 'DICTS',
            icon: 'database',
            submenu: [
                {
                    'title': 'AUTHORS_LIST',
                    'icon': 'team',
                    'path': '/documents/author',
                },
                {
                    'title': 'CATEGORIES',
                    'path': '/documents/category',
                    'icon': 'folder',
                    // 'component': 'documents_category',
                },
                {
                    'title': 'TAGS',
                    'path': '/documents/tag',
                    'icon': 'tag',
                    // 'component': 'documents_tags',
                },
                {
                    'title': 'TRENDS',
                    'path': '/documents/trend',
                    'icon': 'video-camera',
                },
                {
                    'title': 'STOPWORDS',
                    'path': '/documents/stop',
                    'icon': 'stop',
                },
            ]
        },
        {
            'title': 'REPORTS',
            "path": "/reports",
            "icon": "area-chart",
            'subroutes': [
                {
                    'path': '/reports/budget/:id',
                    'component': 'view_budget',
                },
            ],
            'submenu': [
                {
                    'title': 'Yandex',
                    "path": "/reports/yandex",
                    "icon": "table",
                    "component": "yandex_report",
                },
                {
                    'title': 'Material_stats',
                    "path": "/reports/materials_stats",
                    "icon": "line-chart",
                    "component": "material_report",
                },

                {
                    'title': 'Budget',
                    "path": "/reports/budget",
                    "icon": "table",
                    "component": "budget",
                },
                {
                    'title': 'STREAM_STATS',
                    "path": "/reports/stream",
                    "icon": "table",
                    "component": "stream_report",
                },
                {
                    'title': 'Video',
                    "path": "/reports/video",
                    "icon": "table",
                    "component": "video_report",
                },
                {
                    'title': 'Video_VIEWS',
                    "path": "/reports/video_views",
                    "icon": "table",
                    "component": "viqeo_report",
                },
            ]
        },



        {
            'title': 'BANNERS',
            'path': '/documents/banner',
            'icon': 'layout',
        },
        {
            'title': 'WIDGETS',
            'path': '/widgets',
            'icon': 'appstore',
            'component': 'widget',
            'submenu': [
                {
                    'title': 'WIDGETS_EDITOR',
                    'path': '/widgets',
                    'icon': 'appstore',
                    'component': 'widget',
                },
                {
                    'title': 'WIDGETS_ADS',
                    'path': '/widgets_ads',
                    'icon': 'appstore',
                    'component': 'widget',
                },
            ],
            'subroutes': [
                {
                    'path': '/widgets/:id',
                    'component': 'widget_edit',
                },

            ]
        },
        {
            'title': 'PAGES',
            'path': '/documents/page',
            'icon': 'file-text',
        },

        {
            'title': 'USERS',
            'path': '/users',
            'icon': 'user',
            'subroutes': [
                {
                    'path': '/users/:id',
                    'component': 'users_edit',
                },
                {
                    'path': '/users/groups/add',
                    'component': 'add_group',
                },
                {
                    'path': '/users/groups/:id',
                    'component': 'add_group',
                }
            ],
            'submenu': [
                {
                    'title': 'USERS',
                    'path': '/users',
                    'icon': 'user',
                    'component': 'users_index',
                },
                {
                    'title': 'GROUPS',
                    'icon': 'idcard',
                    'path': '/users/groups',
                    'component': 'groups_index',
                },
                {
                    'title': 'PERMISSIONS',
                    'icon': 'idcard',
                    'path': '/users/permissions',
                    'component': 'permission_index',
                }
            ]
        }
    ],
    BANNER_TEXT_COLORS: [
        { 'value': 'black',  'label': 'Без цвета'},
        { 'value': 'white',  'label': 'Белый'},
        { 'value': 'pink',  'label': 'Розовый'},
        { 'value': 'red',  'label': 'Красный'},
    ],
    BANNER_BG_COLORS: [
        { 'value': 'bgc--white',  'label': 'Белый'},
        { 'value': 'bgg--white-ttb',  'label': 'Белый градиент сверху-вниз'},
        { 'value': 'bgg--white-ltr',  'label': 'Белый градиент слева-направо'},
        { 'value': 'bgc--pink',  'label': 'Розовый'},
        { 'value': 'bgg--pink-ttb',  'label': 'Розовый градиент сверху-вниз'},
        { 'value': 'bgg--pink-ltr',  'label': 'Розовый градиент слева-направо'},
        { 'value': 'bgc--red',  'label': 'Красный'},
        { 'value': 'bgg--red-ttb',  'label': 'Красный градиент сверху-вниз'},
        { 'value': 'bgg--red-ltr',  'label': 'Красный градиент слева-направо'},
        { 'value': 'bgc--black',  'label': 'Черный'},
        { 'value': 'bgg--black-ttb',  'label': 'Черный градиент сверху-вниз'},
        { 'value': 'bgg--black-ltr',  'label': 'Черный градиент слева-направо'},
    ],
    WORKFLOW: {
        SHOW_LINK: true,
        PREPARE_LINK: (item) => {
            switch (item.type.toLowerCase()) {
                case "news":
                    return `https://absatz.media/news/${item.publicId}-${item.slug}`;
                case "meaning":
                    return `https://absatz.media/opinion/${item.publicId}-${item.slug}`;
                case "episode":
                    return `https://absatz.media/video/${item.publicId}-${item.slug}`;
                case "gallery":
                    return `https://absatz.media/photo/${item.publicId}-${item.slug}`;
                case "infographic":
                    return `https://absatz.media/infographic/${item.publicId}-${item.slug}`;
                case "battle":
                    return `https://absatz.media/battle/${item.publicId}-${item.slug}`;
                case "show":
                    return ``;
                case "newspaper":
                    return ``;
                case "rnewspaper":
                    return ``;
                case "schedule":
                    return ``;
                case "author":
                    return `https://absatz.media/author/${item.publicId}-${item.slug}`;
                case "trend":
                    return `https://absatz.media/trend/${item.publicId}`;
                case "category":
                    return `https://absatz.media/${item.slug}`;
                case "tag":
                    return `https://absatz.media/tag/${item.slug}`;
                case "page":
                    return `https://absatz.media/${item.slug}`;
                case "podcast":
                    return `https://absatz.media/podcasts/${item.slug}`;
                case "pepisode":
                    return `https://absatz.media/podcasts/${item.category ? item.category.slug : ""}/${item.publicId}-${item.slug}`;
                case "polls":
                    return `https://absatz.media/poll/${item.publicId}`;
                case "quiz":
                    return `https://absatz.media/quiz/${item.publicId}`;
                default:
                    return `https://absatz.media/${item.category ? item.category.slug : ""}/${item.publicId}-${item.slug}`;
            }
        },
        SHOW_SEARCH: true,
        STATUSES: ['DRAFT', 'READY', 'ARCHIVE', 'PUBLISH'],
        STATUSES_COLOR: {
            'DRAFT': 'gray',
            'READY': 'volcano',
            'ARCHIVE': 'purple',
            'PUBLISH': 'green',
        },
        DEFAULT: 'DRAFT',
        LOCK_FOR: [
            'infographic',
            'episode',
            'article',
            'news',
            'gallery',
            'meaning'
        ],
        WORKFLOW_FOR: [
            'episode',
            'article',
            'news',
            'gallery',
            'meaning'
        ],
    },
    WIDGETS: {
        TYPES: ['Quiz', 'Article', 'News', 'Infographic','Meaning', 'Trend', 'Banner', 'Polls', 'Stories', 'Gallery', 'Tag', 'Category', 'Episode', 'Author', 'TEST_TYPE']
    },


    MEDIA: {
        // path for cdn content
        CDN: {
            IMAGES: "https://cdnimages.absatz.media/images",
            VIDEO: "https://cdnimages.absatz.media/video",
            PDF: "https://backend.abzac.media/pdf",
            AUDIO: "https://cdnimages.absatz.media/audio",
        },
        // available copyright sources
        SOURCES: MediaSources,
        // available upload types
        UPLOAD_AVAILABLE: ['jpg', 'gif', 'png', 'jpeg', 'mp4', 'mp3'],
        // available embedded sources
        EMBEDDED_SOURCES: ['youtube.com', 'facebook.com', 'youtu.be', `tvzvezda.ru`],
        EMEDDED_PREPARE: {
            'youtu.be': (path) => {
                let params = path.split('/');
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${params[params.length - 1]}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'youtube.com': (path) => {
                let p = parseUrlParams(path);
                if (!p.v) return ``;
                return (
                    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p.v}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                );
            },
            'facebook.com': (url) => {
                return (
                    `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&width=500&show_text=false&appId=526970354336335&height=280" width="500" height="280" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            },
            'tvzvezda.ru': (url) => {
                return (
                    `<iframe src="${url}" width="500" height="315" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>`
                )
            }
        },
        PREPARE: (path) => {
            if (path.indexOf('mp4/') + 1 > 0) return `https://video.vm.ru${path.replace('https://files.vm.ru', '')}`;
            return path;
        },
    },

};

export default abzac;
